import { createAction, props } from '@ngrx/store';
import {
  IPIMSDomain,
  ProjectPhaseViewModel,
  ProjectViewModel,
  SMEDisciplineViewModel,
} from 'src/app/shared/models/autogenerated';

export const setAllProjects = createAction(
  '[Project] Projects Loaded Success',
  props<{ projects: ProjectViewModel[] | null; currentUserId: string }>()
);

export const loadAllProjects = createAction('[Project] Load Projects');

export const loadCurrentProject = createAction(
  '[Project] Load Current Project'
);

export const loadEditProjectById = createAction(
  '[Project] Load Edit Project by Id',
  props<{ projectId: string }>()
);

export const setCurrentProject = createAction(
  '[Project] Load Current Project Success',
  props<{ project: ProjectViewModel | null }>()
);

export const setEditProjectForm = createAction(
  '[Project] set Edit Project Form',
  props<{ project: ProjectViewModel | null }>()
);

export const updateProject = createAction('[Project] Update Project');

export const updateProjectSuccess = createAction(
  '[Project] Update Project Success',
  props<{ project: ProjectViewModel | null }>()
);

export const invalidateCurrentProject = createAction(
  '[Project] Invalidate Project'
);

export const updateCurrentProjectPhase = createAction(
  '[ProjectPhase] Update ProjectPhase'
);

export const updateCurrentProjectPhaseSuccess = createAction(
  '[ProjectPhase] Update ProjectPhase Success',
  props<{ project: ProjectPhaseViewModel | null }>()
);

export const loadCurrentPhase = createAction('[Project] Load Current Phase');

export const setCurrentPhase = createAction(
  '[Project] Load Current Phase Success',
  props<{ phase: ProjectPhaseViewModel | undefined }>()
);

export const invalidateCurrentPhase = createAction(
  '[Project] Invalidate Current Phase'
);

export const loadDisciplinesForCurrentPhase = createAction(
  '[Project] Load Disciplines For Current Phase'
);

export const setDisciplinesForCurrentPhase = createAction(
  '[Project] Load Disciplines For Current Phase Success',
  props<{ disciplines: SMEDisciplineViewModel[] | undefined }>()
);

export const setStatusForCurrentPhase = createAction(
  '[Project] Set Status For Current Phase',
  props<{ status: string }>()
);

export const downloadTOR = createAction('[Project] Download TOR');

export const downloadIPIMS = createAction('[ProjectPhase] Download iPIMS');

export const exportDeliverableRequestToProject = createAction(
  '[ProjectPhase] Export Deliverable Request to Project'
);

export const clearProjectForm = createAction(
  '[EditProjectForm] Clear project form'
);

export const clearPhaseFormUnsavedChanges = createAction(
  '[EditPhaseForm] Clear changes'
);

export const setProjectPageView = createAction(
  '[Project] Set page view',
  props<{ projectPageView: string }>()
);

export const loadIPIMSDomains = createAction('[IPIMS DA] Load Domains');

export const loadIPIMSDomainsSuccess = createAction(
  '[IPIMS DA] Load Domains Success',
  props<{ domains: IPIMSDomain[] }>()
);
